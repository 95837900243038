import Swal from 'sweetalert2';

/**
 * Clase con la lógica de las ventanas modales custom
 */
export class ModalLogic {
  /**
   * Obtener modal generico
   */
  public getModal(title: string, description: string) {
    Swal.fire({
      customClass: {
        popup: 'container-thin-request',
        confirmButton: 'button-confirm',
      },
      // tslint:disable-next-line: prefer-template
      html: `
      <br />
      <p class="text-title-request">
      ` + title + `
      </p>
      <p class="text-content-request">` + description + `
      </p>
      `,
      width: '800',
      heightAuto: true,
      confirmButtonText: 'Confirmar',
      allowOutsideClick: false,
      position: 'top',
    });
  }

  /**
   * Obtener modal de solicitudes
   */
  public getRequestModal() {
    Swal.fire({
      customClass: {
        popup: 'container-thin-request',
        confirmButton: 'button-confirm',
      },
      html: `
      <br />
      <p class="text-title-request">
        Solicitud enviada
      </p>
      <p class="text-subtitle-request">con éxito</p>
      <br />
      <p class="text-content-request">
      Recibimos tu solicitud, realizaremos la validación y 
      procederemos a gestionar y dar respuesta entre tres 
      y quince días hábiles máximo.
      <br />
      <br />
      Te enviamos al correo remitente el número de Radicado 
      de tu solicitud, con el cual podrás hacer seguimiento.
        <br />
        <br />
        <br />
        Por tu seguridad, si tú requerimiento incluye 
        información sensible, la respuesta será enviada 
        al correo que tienes registrado en Porvenir.
      </p>
      `,
      width: '800',
      heightAuto: true,
      confirmButtonText: 'Confirmar',
      position: 'top',
    }).then(() => {
      location.href = '/';
    });
  }

  /**
   * Obtiene modal de entidades judiciales
   */
  public getJudicialEntityModal() {
    Swal.fire({
      customClass: {
        popup: 'container-thickness-entity',
        confirmButton: 'button-ok',
        image: 'warning-image',
      },
      imageUrl: './assets/images/Gráficos-08.svg',
      imageWidth: '250',
      imageHeight: '250',
      imageAlt: 'warning',
      html: `
        <p class="text-title-entity text-title-modal-responsive">Entidad judicial</p>
        <p class="text-content-entity text-content-modal-responsive">
          Le informamos que este formulario NO atenderá la
          recepción de ningún tipo de solicitud o
          requerimiento administrativo o judicial.
        </p>
        <p class="text-content-entity text-content-modal-responsive">
          Con el fin de brindar  un  mejor servicio le
          agradecemos que sus solicitudes y
          requerimientos los remita al correo
          <a>notificacionesjudiciales@porvenir.com.co</a> donde le darán
          trámite.
      </p>
      `,
      width: '850',
      heightAuto: true,
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      position: 'top',
    });
  }

  /**
   * Obtiene el modal de EPS
   */
  public getEpsModal() {
    Swal.fire({
      customClass: {
        popup: 'container-thickness-eps',
        confirmButton: 'button-confirm-eps',
        cancelButton: 'button-cancel-eps',
        image: 'warning-image-eps',
      },
      imageUrl: './assets/images/Gráficos-08.svg',
      imageWidth: '250',
      imageHeight: '250',
      imageAlt: 'warning',
      html: `
        <p class="text-title-eps">EPS</p>
        <p class="text-content-eps-1">
        Podrás continuar con este formulario si tu solicitud sólo es para
        <strong>anexar el concepto de rehabilitación de clientes afiliados
        al Fondo de Pensiones Obligatorias administrador por Porvenir.</strong>
        </p>
        <br />

        <p class="text-content-eps-2">
        Otras solicitudes del proceso de calificación de la invalidez como: pago honorarios,
        pronunciamiento de dictámenes, apelaciones, controversias, al buzón de correo:
        <strong>servicioalcliente@segurosalfa.com.co</strong>
        </p>
      `,
      width: '1100',
      heightAuto: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'CONTINUAR',
      cancelButtonText: 'SALIR',
      allowOutsideClick: false,
      position: 'top',
    });
  }

  /**
   * Obtiene el modal de ARL
   */
  public getArlModal() {
    Swal.fire({
      customClass: {
        popup: 'container-thickness-arl',
        confirmButton: 'button-exit-arl',
        image: 'warning-image-arl',
      },
      imageUrl: './assets/images/Gráficos-08.svg',
      imageWidth: '250',
      imageHeight: '250',
      imageAlt: 'warning',
      html: `
        <p class="text-title-arl">
          Aseguradora de riesgos laborales
          <br />
          (ARL)
        </p>
        <br />
        <p class="text-content-arl">
        Las solicitudes del proceso de calificación de la invalidez como:
        <strong>pago de honorarios, pronunciamiento de dictámenes, apelaciones, controversias,</strong> deberás remitirlas al buzón de correo:
        </p>
        <br />
        <p class="text-content-arl">
            <strong>servicioalcliente@segurosalfa.com.co</strong>
        </p>
      `,
      width: '1100',
      heightAuto: true,
      confirmButtonText: 'SALIR',
      allowOutsideClick: false,
      position: 'top',
    });
  }

  /**
   * Obtiene el modal de junta de calificación
   */
  public getQualifyModal() {
    Swal.fire({
      customClass: {
        popup: 'container-thickness-qualify',
        confirmButton: 'button-exit-qualify',
        image: 'warning-image-qualify',
      },
      imageUrl: './assets/images/Gráficos-08.svg',
      imageWidth: '250',
      imageHeight: '250',
      imageAlt: 'warning',
      html: `
        <br />
        <p class="text-title-qualify">Junta de calificación</p>
        <br />
        <p class="text-content-qualify-1">
          En este formulario no podrás solicitar ni notificar
          temas relacionados con el proceso de calificación.
        </p>
        <br />
        <br />
        <p class="text-content-qualify-2">
          Todas las solicitudes deberán ser enviadas al correo de la Aseguradora Alfa,
          entidad con la cual Porvenir tiene contratado el seguro previsional,
          que cubre las contingencias de invalidez y muerte.
        </p>
        <br />
        <br />
        <p class="text-content-qualify-1">
            <strong>servicioalcliente@segurosalfa.com.co</strong>
            <strong>o citaciones.alfa@codess.org.co</strong>
        </p>
        <br />
      `,
      width: '1100',
      heightAuto: true,
      confirmButtonText: 'SALIR',
      allowOutsideClick: false,
      position: 'top',
    });
  }

  /**
   * Obtiene el modal de información de cargue de archivos
   */
  public getFileUploadInformationModal(onAccept: () => void) : void {
    Swal.fire({
      customClass: {
        popup: 'container-thickness-qualify',
        confirmButton: 'button-exit-qualify',
        image: 'warning-image-qualify',
      },
      imageUrl: './assets/images/Gráficos-08.svg',
      imageWidth: '250',
      imageHeight: '250',
      imageAlt: 'warning',
      html: `
        <p class="text-content-request">
        Ten en cuenta al adjuntar documentos a tu solicitud validar el peso de máximo de 9 MB, así como 
        los formatos permitidos JPG, JPEG, PNG y PDF y que estos archivos no esten protegidos con 
        contraseñas, no estén comprimidos (extensiones como zip, rar, otros) o que estén dañados, toda 
        vez que no están permitidos y tu solicitud no podrá ser atendida
        </p>
      `,
      width: '1100',
      heightAuto: true,
      confirmButtonText: 'ACEPTAR',
      allowOutsideClick: false,
      position: 'center',
      preConfirm: () => { 
        onAccept(); 
      }
    });
  }

  /**
   * Obtiene el modal de advertencia a imagen pesada
   */
  public getHeavyImageModal() : void {
    Swal.fire({
      customClass: {
        popup: 'container-thickness-qualify',
        confirmButton: 'button-exit-qualify',
        image: 'warning-image-qualify',
      },
      imageUrl: './assets/images/Gráficos-08.svg',
      imageWidth: '250',
      imageHeight: '250',
      imageAlt: 'warning',
      html: `
        <p class="text-content-request">
        El archivo que intentas cargar supera el peso permitido. Para continuar con tu solicitud, te 
        pedimos que: 1. Verifiques que el peso del archivo, Asegúrate que tus archivos no superen el peso 
        permitido (9MB). 2. Vuelve a cargar los archivos correctamente.
        </p>
      `,
      width: '1100',
      heightAuto: true,
      confirmButtonText: 'ACEPTAR',
      allowOutsideClick: false,
      position: 'center',
    });
  }

  /**
   * Obtiene el modal de advertencia de formato no permitido
   */
  public getFormatNotAllowedModal() : void {
    Swal.fire({
      customClass: {
        popup: 'container-thickness-qualify',
        confirmButton: 'button-exit-qualify',
        image: 'warning-image-qualify',
      },
      imageUrl: './assets/images/Gráficos-08.svg',
      imageWidth: '250',
      imageHeight: '250',
      imageAlt: 'warning',
      html: `
        <p class="text-content-request">
        El archivo que intentas cargar es un formato no permitido. Para continuar con tu solicitud, te 
        pedimos que: 1. Verifiques que el formato del documento a adjuntar (JPG, JPEG, PNG y PDF) 2. 
        Vuelve a cargar los archivos correctamente.
        </p>
      `,
      width: '1100',
      heightAuto: true,
      confirmButtonText: 'ACEPTAR',
      allowOutsideClick: false,
      position: 'center',
    });
  }

  /**
   * Obtiene el modal de advertencia de archivos corruptos
   * o que poseen contraseña
   */
  public getCorruptedOrProtectedFilesModal() : void {
    Swal.fire({
      customClass: {
        popup: 'container-thickness-qualify',
        confirmButton: 'button-exit-qualify',
        image: 'warning-image-qualify',
      },
      imageUrl: './assets/images/Gráficos-08.svg',
      imageWidth: '250',
      imageHeight: '250',
      imageAlt: 'warning',
      html: `
        <p class="text-content-request">
        El archivo que intentas cargar presenta problemas de accesibilidad (Contraseña o daños). Para 
        continuar con tu solicitud, te pedimos que: 1. Verifiques que el archivo no esté protegido con 
        contraseña. 2. Asegúrate que el archivo esté completo y sin daños. 3. Vuelvas a cargar el archivo 
        correctamente.
        </p>
      `,
      width: '1100',
      heightAuto: true,
      confirmButtonText: 'ACEPTAR',
      allowOutsideClick: false,
      position: 'center',
    });
  }

  /**
   * Obtiene el modal duplicidad de interacción
   */
  public getDuplicatedInteractionModal() {
    Swal.fire({
      customClass: {
        popup: 'container-thickness-arl',
        confirmButton: 'button-exit-arl',
      },
      html: `
        <p class="text-content-arl">
        Estimado usuario, su solicitud ya ha sido ingresada previamente. Por favor valide que la notificación del radicado
        haya llegado al correo que registró. Si tiene otra solicitud diferente por favor continuar.
        </p>
      `,
      width: '1100',
      heightAuto: true,
      confirmButtonText: 'ACEPTAR',
      allowOutsideClick: false,
      position: 'center',
    });
  }
}
