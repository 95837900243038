<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" enctype="multipart/form-data">

  <div fxLayout="column">
    <!--Row Or Column Load Files-->
    <div fxLayout="row wrap" class="row-fields">
      <div fxLayout="row" fxFlex="10" fxFlex.sm="10" fxFlex.xs="10" fxLayoutAlign="center start">
        <div class="container-tooltip-load">
          <img class="info-image-load" src="../../../../assets/images/Gráficos-12.svg" />
          <div class="tooltip-load-file" fxLayoutAlign="center start">
            <p>
              Los formatos permitidos son JPG, JPEG, PNG y PDF y el
              peso máximo debe ser de 9Mb
            </p>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxFlex="50" fxFlex.sm="80" fxFlex.xs="80" fxLayoutAlign="center start">

        <label class="btn btn-default">

        </label>

        <span class="text-load-icon">Adjunta documentos para tu solicitud</span>
      </div>
      <div fxLayout="row" fxFlex="40" fxFlex.sm="10" fxFlex.xs="10" fxLayoutAlign="center start">
        <!--Button Resolution >= 960px-->
        <button *ngIf="screenWidth >= 960" type="button" class="button-file-active button-load-file" mat-raised-button
          (click)="this.showInputFilePopUp()">Seleccionar archivo
          <mat-icon class="icon-custom icon-resize" svgIcon="choose_file"></mat-icon>
        </button>
        <!--Button Resolution < 960px-->
        <button *ngIf="screenWidth < 960" type="button" class="button-file-active button-load-file" mat-mini-fab
          (click)="this.showInputFilePopUp()">
          <mat-icon class="icon-resize" svgIcon="choose_file"></mat-icon>
        </button>
        <div fxLayout="column">
          <div fxLayout="row wrap">
            <input #fileInput2 type="file" class="fileUpload" name="melopela" multiple (change)="upload($event)" />
          </div>
        </div>
      </div>
    </div>

    <!--Row Or Column Text Info Files Adjustment-->
    <div fxLayout="row wrap" class="row-fields" fxFlexOffset="-90px" fxFlexOffset.xs="-70px">
      <div fxLayout="column wrap" fxFlex>
        <div fxLayout="column" fxFlexOffset="-55px">
          <label class="labels-form">Archivos adjuntos</label>
        </div>
      </div>
    </div>
    <!--NgIf validates empty object-->
    <div *ngIf="selectedFiles != null">
      <div *ngFor="let file of dataSelected$ | async">
        <div *ngIf="valueSpinner === 100">
          <!--Row Or Column Files Adjustment-->
          <div fxLayout="row wrap" class="row-fields" fxFlexOffset="-10px" fxFlex="100">
            <div class="container-files-load" fxLayout="row wrap" fxFlex="100">
              <!--Icon File-->
              <div fxLayout="row" fxFlexOffset.xs="3" fxFlex="10" fxLayoutAlign="start center">
                <img class="file-icons" src="../../../../assets/images/Gráficos-02.svg" />
              </div>
              <!--Name File-->
              <div fxLayout="row" fxFlexOffset.xs="3" fxFlex.xs="64" fxFlex="65" fxFlexOffset="5" fxLayoutAlign="start center">
                <p class="text-file-load">{{file.name}}</p>
              </div>
              <!--Eye File Detail-->
              <!--<div fxLayout="row" fxFlex="5" fxFlex.xs="10" fxLayoutAlign="center center">
                <a href="/"><img class="file-icons" src="../../../../assets/images/Gráficos-07.svg" /></a>
              </div>-->
              <!--Delete File-->
              <div fxLayout="row" fxFlexOffset.xs="10" fxFlex.xs="10" fxFlexOffset.sm="10" fxFlex.sm="10" fxFlex="5" fxLayoutAlign="center center">
                <a (click)="deleteItem(file.name)"><img class="file-icons img-delete"
                    src="../../../../assets/images/Gráficos-09.svg" /></a>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="valueSpinner >= 0 && valueSpinner != 100 && !validateFiles">
          <!--Row Or Column Files Loading-->
          <div fxLayout="row wrap" class="row-fields" fxFlexOffset="-10px" fxFlex="100">
            <div class="container-files-load" fxLayout="row wrap" fxFlex="100">
              <!--Icon File-->
              <div fxLayout="row" fxFlex="10" fxFlex.xs="10" fxLayoutAlign="start center">
                <img class="file-icons" src="../../../../assets/images/Gráficos-02.svg" />
              </div>
              <!--Container Progress Bar-->
              <div class="container-progress" fxLayout="column wrap" fxFlex="80" fxFlex.xs="70">
                <!--Name File-->
                <div fxLayout="column">
                  <p class="text-file-load">{{file.name}}</p>
                </div>
                <!--Progress Bar-->
                <div class="progress-data" fxLayout="column">
                  <mat-progress-bar mode="determinate" value={{valueSpinner}}></mat-progress-bar>
                </div>
              </div>
              <!--Fill-->
              <div fxLayout="row" fxFlex="5" fxFlex.xs="10">
                <div fxFlexFill></div>
              </div>
              <!--Delete File Load-->
              <div fxLayout="row" fxFlex="5" fxFlex.xs="10" fxLayoutAlign="center center">
                <a href="/"><img class="file-icons" src="../../../../assets/images/Gráficos-10.svg" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="this.validateSizeFiles">
        <div fxLayout="column wrap" fxFlex="85">
          <div fxLayout="column">
            <p class="text-file-load-2">{{this.messageValidateFiles}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div *ngIf="validateFiles">
          <!--Row Or Column File Load Failed-->
          <div fxLayout="row wrap" class="row-fields" fxFlexOffset="-10px" fxFlex="100">
            <div class="container-failed-load" fxLayout="row wrap" fxFlex="100">
              <!--Icon File-->
              <div fxLayout="row" fxFlex="10">
                <img class="file-icons-2" src="../../../../assets/images/Gráficos-13.svg" width="120" height="120" />
              </div>
              <!--Data File-->
              <div *ngIf="!validateSizeFiles && validateFiles" fxLayout="row" fxFlex="70" >
                <p class="text-file-load-2"><strong>{{this.messageValidateFiles}}</strong>
                </p>
              </div>
              <!--Failed File Load-->
              <div fxLayout="row" fxFlex="10">
                <img class="file-icons-2" src="../../../../assets/images/Gráficos-03.svg" width="150" height="150" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
