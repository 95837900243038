import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CaptchaDto, InteractionDto } from '@prf/interaction';
import { DataRequestDto, DocumentsDto, FormDataDto, JsonDataDto, KeywordsDto } from '@prf/on-base';
import { OnBaseDto } from '@prf/on-base/lib/models/on-base.dto';
import { QuerySubjectDto } from '@prf/query-subject';
import { TypeCustomerDto } from '@prf/type-customer';
import { TypeDocumentDto } from '@prf/type-document';
import { AceptacionesResultDto } from '@prf/ws-data-treatment';
import { Guid } from 'guid-typescript';
import { forkJoin, Observable } from 'rxjs';
import { ModalLogic } from '../../../shared/logic/modal.logic';
import { EncryptService } from '../../../shared/services/encrypt/encrypt.service';
import { Data } from '../../Models/data.model';
import { FileData } from '../../Models/file-data.model';
import { Interaction } from '../../Models/interaction.model';
import { ModalsComponent } from '../modals/modals.component';
import { ContactUsFacade } from './contact-us.facade';
import emojiRegex from 'emoji-regex';

/**
 * Componente de Contacto
 */
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  /**
   * Servicio encargado de interactuar con el servicio de encriptacion
   */
  private readonly encryptService: EncryptService;
  /**
   * Fachada Login
   */
  public readonly facade: ContactUsFacade;
  /**
   * Observable - Tipos de documentos
   */
  public readonly typeDocuments$: Observable<TypeDocumentDto[]> = new Observable();
  /**
   * Observable - Tipos de clientes
   */
  public readonly typeCustomers$: Observable<TypeCustomerDto[]> = new Observable();
  /**
   * Observable - Asustos de consulta
   */
  public readonly querySubject$: Observable<QuerySubjectDto[]> = new Observable();
  /**
   * Observable que indica el estado de habilitación/deshabilitación del botón de archivo.
   */
  public infoDisableButtonFileUp$: Observable<boolean> = new Observable();
  /**
   * Información de los archivos seleccionados
   */
  public dataSelected$ = new Observable<FileData[]>();
  /**
   * Formulario
   */
  public frmGroup: FormGroup;
  /**
   * Lógica de Modals
   */
  public modalLogic: ModalLogic = new ModalLogic();
  /**
   * Ancho de la pantalla
   */
  public screenWidth!: number;
  /**
   * Número de caracteres en la descripción
   */
  public valor: number;
  /**
   * Provee una ventana flotante
   */
  public dialog: MatDialog;
  /**
   * Servicio para registrar y mostrar iconos usados por el componente `<mat-icon>`.
   */
  private readonly matIconRegistry: MatIconRegistry;
  /**
   * DomSanitizer ayuda a prevenir errores de Cross Site Scripting Security (XSS) al desinfectar valores para ser seguros de usar en los diferentes contextos DOM.
   */
  private readonly domSanitizer: DomSanitizer;

  @HostListener('window:resize', ['$event'])
  public getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  /**
   * Constructor del componente Contact-Us
   * @param facade Facade Contact-Us
   * @param fb Creates an AbstractControl from a user-specified configuration.
   * @param matIconRegistry Servicio para registrar y mostrar iconos usados por el componente `<mat-icon>`.
   * @param domSanitizer Ayuda a prevenir errores de Cross Site Scripting Security (XSS) al desinfectar valores para ser seguros de usar en los diferentes contextos DOM.
   */
  public constructor(
    facade: ContactUsFacade,
    fb: FormBuilder,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    dialog: MatDialog,
    encryptService: EncryptService,
  ) {
    this.valor = 0;
    this.facade = facade;
    this.typeDocuments$ = this.facade.typeDocuments$;
    this.typeCustomers$ = this.facade.typeCustomers$;
    this.querySubject$ = this.facade.querySubjects$;
    this.encryptService = encryptService;
    this.dataSelected$ = this.facade.dataSelected$;
    this.infoDisableButtonFileUp$ = this.facade.infoDisableButtonFileUp$;
    this.frmGroup = fb.group({
      typeCustomer: ['', Validators.required],
      name: ['', [Validators.required, Validators.maxLength(128), Validators.pattern(/^[a-zA-ZÀ-ÿ ñÑ]{1,64}$/)]],
      email: [
        '',
        [
          Validators.required,
          Validators.maxLength(200),
          Validators.pattern(/^(?!.*\.\.)([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/),
        ],
      ],
      emailConfirmation: [
        '',
        [
          Validators.required,
          Validators.maxLength(200),
          Validators.pattern(/^(?!.*\.\.)([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/),
        ]
      ],
      typeDocument: ['', Validators.required],
      numberDocument: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(5), Validators.pattern(/^(?!0+$)[0-9]{5,10}$/)]],
      country: ['', [Validators.required, Validators.maxLength(64), Validators.pattern(/^[a-zA-ZÀ-ÿ ñÑ]{1,64}$/)]],
      municipality: ['', [Validators.required, Validators.maxLength(64), Validators.pattern(/^[a-zA-ZÀ-ÿ ñÑ]{1,64}$/)]],
      department: ['', [Validators.required, Validators.maxLength(64), Validators.pattern(/^[a-zA-ZÀ-ÿ ñÑ]{1,64}$/)]],
      subject: ['', Validators.required],
      detailSubject: ['', [Validators.required, Validators.maxLength(500), this.noEmojiValidator()]],
      phone: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^[0-9]{1,10}$/)]],
      address: ['', [Validators.required, Validators.maxLength(80), this.noEmojiValidator()]],
      dataTreatmentYes: [false, Validators.requiredTrue],
      dataTreatmentNo: false,
      acceptedTermsYes: [false, Validators.requiredTrue],
      acceptedTermsNo: false,
      recaptchaReactive: [null, Validators.required],
    },
      { validators: this.emailConfirmationValidator() }
    );
    this.matIconRegistry = matIconRegistry;
    this.domSanitizer = domSanitizer;

    this.matIconRegistry.addSvgIcon(
      `choose_file`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/Gráficos_Mesa de trabajo1.svg'),
    );
    this.dialog = dialog;

    this.getScreenSize();
  }

  /**
   * Método encargado de inicializar el componente
   */
  public ngOnInit(): void {
    this.facade.allTypeDocuments(() => {
      this.facade.allTypeCustomers(() => {
        this.facade.allQuerySubjects();
      });
    });
  }
  /**
   * Metodo para el control del checkbox de tratamiento de datos
   */
  public toggleCheckboxData(checkboxName: string) {
    if (checkboxName === 'dataTreatmentYes') {
      const habeasControl = this.frmGroup.get('dataTreatmentYes');
      const habeas2Control = this.frmGroup.get('dataTreatmentNo');

      if (habeasControl?.value) {
        habeas2Control?.setValue(false);
      }
    } else if (checkboxName === 'dataTreatmentNo') {
      const habeasControl = this.frmGroup.get('dataTreatmentYes');
      const habeas2Control = this.frmGroup.get('dataTreatmentNo');

      if (habeas2Control?.value) {
        habeasControl?.setValue(false);
      }
    }
  }
  /**
   * Metodo para el control del checkbox de terminos y condiciones
   */
  public toggleCheckboxTerms(checkboxName: string) {
    if (checkboxName === 'acceptedTermsYes') {
      const habeas3Control = this.frmGroup.get('acceptedTermsYes');
      const habeas4Control = this.frmGroup.get('acceptedTermsNo');

      if (habeas3Control?.value) {
        habeas4Control?.setValue(false);
      }
    } else if (checkboxName === 'acceptedTermsNo') {
      const habeas3Control = this.frmGroup.get('acceptedTermsYes');
      const habeas4Control = this.frmGroup.get('acceptedTermsNo');

      if (habeas4Control?.value) {
        habeas3Control?.setValue(false);
      }
    }
  }
  /**
   * Método que obtiene tratamiento de datos
   */
  public getTerminosyCondiciones() {
    this.facade.spinner.show();
    let datos = '';
    this.facade.getTratamientoDatos().subscribe((x) => {
      datos = `${x.listaDocumentos?.shift()?.contenidoDocumento || ''},Tratamiento de datos`;
      this.facade.spinner.hide();
      this.dialog.open(ModalsComponent, {
        width: '850px',
        height: '600px',
        closeOnNavigation: true,
        data: datos,
      });
    });
  }
  /**
   * Método que obtiene términos y condiciones
   */
  public getTerminosyCondiciones2() {
    this.facade.spinner.show();
    let datos = '';
    this.facade.getTermsAndConditions().subscribe((x) => {
      datos = `${x.listaDocumentos?.shift()?.contenidoDocumento || ''},Términos y condiciones`;
      this.facade.spinner.hide();
      this.dialog.open(ModalsComponent, {
        width: '850px',
        height: '600px',
        closeOnNavigation: true,
        data: datos,
      });
    });
  }

  /**
   * Método de guardado
   */
  public onSubmit() {
    // Validar el botón para evitar envíos duplicados
    if (this.validateButton()) {
      return;
    }

    // Obtener los datos del formulario
    const form = this.frmGroup.value;

    // Preparar los datos para enviar a OnBase
    const dataToSend = this.prepareDataRequestDto(form);

    // Crear un modelo de datos con la información del formulario
    const dataModel: Data = {
      customerType: form.typeCustomer.name,
      typeDocument: form.typeDocument.mask,
      querySubject: form.subject.name,
      numberDocument: form.numberDocument,
      name: form.name,
      email: form.email,
      phone: form.phone,
      country: form.country,
      department: form.department,
      municipality: form.municipality,
      address: form.address,
      queryDetail: form.detailSubject,
      attachments: dataToSend.documents && dataToSend.documents.length > 0 ? true : false,
      countAttachments: dataToSend.documents && dataToSend.documents.length ? dataToSend.documents.length : 0,
      dataTreatment: form.dataTreatmentYes,
      acceptedTerms: form.acceptedTermsYes,
    };

    // Crear un modelo de interacción para el registro de la interacción
    const interactionModel: Interaction = {
      idInteraction: 1,
      // Generar un GUID para la interacción
      guId: Guid.create().toString(),
      // Datos del modelo de datos creado anteriormente
      data: dataModel,
      date: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString(),
    };
    // Crear un DTO de interacción para enviar al servicio de autenticación
    const interactionDto: InteractionDto = {
      typeDocument: form.typeDocument.mask,
      numberDocument: form.numberDocument,
      // Convertir el modelo de interacción a JSON y limpiar espacios en blanco
      payload: `${JSON.stringify(interactionModel)}`.trim(),
      version: '1',
      idSequence: 2,
    };

    // Crear un DTO de Captcha para enviar al servicio de autenticación
    const captcha: CaptchaDto = {
      googletoken: form.recaptchaReactive,
    };

    // Llamar al método de autenticación con los DTOs y modelos preparados
    this.auth(captcha, dataToSend, dataModel, interactionDto);
  }

  /**
   * Prepara los datos del formulario y archivos adjuntos para enviar a OnBase.
   * @param form Datos del formulario obtenidos del usuario.
   * @returns DTO que contiene los datos estructurados para enviar a OnBase.
   */
  public prepareDataRequestDto(form: any) {
    // Extraer los archivos adjuntos del componente
    const attachedFiles = this.extractAttachedFiles();

    // Inicializar la estructura JSON de datos
    const dataJsonInitialized = this.initializeJsonData();

    // Preparar los archivos adjuntos para enviar
    const fileToSendPost = this.prepareFilesToSend(attachedFiles, dataJsonInitialized);

    // Preparar los datos del formulario para enviar
    const dataFormToPost = this.prepareFormDataToPost(form, attachedFiles);

    // Preparar los datos del formulario para enviar
    const dataForm = this.prepareOnBaseDto(dataFormToPost);

    // Crear el DTO final que contiene los datos y archivos estructurados
    const dataDto: DataRequestDto = {
      onBaseDto: dataForm,
      documents: fileToSendPost,
    };

    return dataDto;
  }

  /**
   * Extrae y estructura los archivos adjuntos seleccionados para enviar.
   * @returns Un arreglo de objetos FormDataDto que representan los archivos adjuntos seleccionados.
   */
  private extractAttachedFiles(): FormDataDto[] {
    const attachedFiles: FormDataDto[] = [];
    let adjuntos: FileData[] = [];

    // Suscripción para obtener la lista de archivos seleccionados
    this.dataSelected$.subscribe((response) => (adjuntos = response));

    // Procesar los archivos seleccionados si existen
    if (adjuntos && adjuntos.length > 0) {
      for (const adjunto of adjuntos) {
        if (adjunto && adjunto.content) {
          // Crear objeto FormDataDto con los datos del archivo adjunto
          const attachedFile: FormDataDto = {
            content: adjunto.content,
            type: adjunto.type,
            name: adjunto.name,
            size: adjunto.size,
            lastModified: adjunto.lastModified,
          };
          attachedFiles.push(attachedFile);
        }
      }
    }

    return attachedFiles;
  }

  /**
   * Inicializa la estructura JSON de datos para su posterior uso.
   * @returns Un objeto JsonDataDto inicializado con valores por defecto.
   */
  private initializeJsonData(): JsonDataDto {
    return {
      Keywords: [{ Name: '', Value: 0 }],
    };
  }

  /**
   * Prepara los archivos adjuntos para ser enviados como parte de la solicitud.
   * @param attachedFiles Arreglo de objetos FormDataDto que representan los archivos adjuntos seleccionados.
   * @param dataJsonInitialized Objeto JsonDataDto inicializado para incluir en cada archivo adjunto.
   * @returns Un arreglo de objetos DocumentsDto listos para ser enviados.
   */
  private prepareFilesToSend(attachedFiles: FormDataDto[], dataJsonInitialized: JsonDataDto): DocumentsDto[] {
    const fileToSendPost: DocumentsDto[] = [];

    // Procesar los archivos adjuntos si existen
    if (attachedFiles && attachedFiles.length > 0) {
      for (const file of attachedFiles) {
        if (file && file.content) {
          // Crear objeto DocumentsDto con los datos del archivo adjunto y datos JSON inicializados
          const fileData: DocumentsDto = {
            multipartForm: file,
            documentTypeName: 'FE - Adjuntos',
            sourceApplication: 'PorvenirSrv',
            jsonData: dataJsonInitialized,
            kWIDs: '',
            kTGIDs: '',
          };
          fileToSendPost.push(fileData);
        }
      }
    }

    return fileToSendPost;
  }

  /**
   * Prepara los datos del formulario para ser enviados como parte de la solicitud.
   * @param form Datos del formulario obtenidos del usuario.
   * @param adjuntos Arreglo de objetos FileData que representan los archivos adjuntos seleccionados.
   * @returns Un arreglo de objetos KeywordsDto listos para ser enviados.
   */
  private prepareFormDataToPost(form: any, adjuntos: FileData[]): KeywordsDto[] {
    // Obtener la fecha actual
    const date = this.getDate();
    // Inicializar el arreglo de datos del formulario
    const dataFormToPost: KeywordsDto[] = [
      { name: 'ID Ventanilla Virtual', value: '339' },
      { name: 'Solicitante', value: `${form.typeCustomer.name}` },
      { name: 'Nombre completo razón social', value: `${form.name}` },
      { name: 'Tipo de Documento', value: `${form.typeDocument.mask} - ${form.typeDocument.name}` },
      { name: 'Asunto Consulta', value: `${form.subject.name}` },
      { name: 'Número documento', value: `${form.numberDocument}` },
      { name: 'Correo electrónico', value: `${form.email}` },
      { name: 'Teléfono Contacto', value: `${form.phone}` },
      { name: 'País', value: `${form.country}` },
      { name: 'Departamento/ Estado/ Provincia', value: `${form.department}` },
      { name: 'Municipio/Ciudad', value: `${form.municipality}` },
      { name: 'Dirección', value: `${form.address}` },
      { name: 'Contar adjuntos', value: `${adjuntos.length}` },
      { name: 'Tratamiento de datos', value: 'Si, acepto' },
      { name: 'Fecha creación', value: `${date}` },
      { name: 'Dia', value: `${date.split('-')[2].split(' ')[0]}` },
      { name: 'Hora', value: `${date.split(' ')[1].split(':')[0]}` },
      { name: 'Minuto', value: `${date.split(' ')[1].split(':')[1]}` },
      { name: 'Mes', value: `${date.split('-')[1]}` },
      { name: 'Segundo', value: `${date.split(' ')[1].split(':')[2]}` },
      { name: 'Año', value: `${date.split('-')[0]}` },
    ];

    // Procesar el detalle de la consulta si supera los 200 caracteres
    const detalleConsulta = form.detailSubject;
    const detalleConsultaLength = detalleConsulta.length;

    if (detalleConsultaLength > 200) {
      const numberOfSegments = Math.ceil(detalleConsultaLength / 200);

      for (let i = 0; i < numberOfSegments; i++) {
        const start = i * 200;
        const end = start + 200;
        const segment = detalleConsulta.substring(start, end);

        dataFormToPost.push({
          name: 'Detalle consulta',
          value: segment,
        });
      }
    } else {
      // Agregar el detalle de la consulta si no supera los 200 caracteres
      dataFormToPost.push({
        name: 'Detalle consulta',
        value: detalleConsulta,
      });
    }

    return dataFormToPost;
  }
  /**
   * Prepara los datos estructurados para OnBase basados en la lista de palabras clave proporcionada.
   * @param keywordsList Arreglo de objetos KeywordsDto que representan las palabras clave para el formulario.
   * @returns Un objeto OnBaseDto estructurado con la información necesaria para OnBase.
   */
  private prepareOnBaseDto(keywordsList: KeywordsDto[]): OnBaseDto {
    return {
      sourceApplication: 'PorvenirSrv',
      eFormList: [
        {
          documentTypeName: 'FrmVentanillaVirtual',
          keyWords: keywordsList,
          keywordGroup: null,
        },
      ],
    };
  }

  /**
   * Convierte una cadena JSON en un objeto FormData adecuado para enviar como archivo.
   * @param jsonString Cadena JSON que se convertirá en FormData.
   * @returns Objeto FormData con el JSON convertido como archivo 'file'.
   */
  public jsonToFormData(jsonString: string): FormData {
    const formData = new FormData();
    formData.append('file', new Blob([jsonString], { type: 'application/json' }));

    return formData;
  }

  /**
   * Realiza la autenticación, aceptación de términos y condiciones, y tratamiento de datos antes de guardar una interacción y enviar correos electrónicos.
   * @param captcha Objeto CaptchaDto que contiene el token de Google reCAPTCHA para la autenticación.
   * @param dataDto Objeto DataRequestDto que contiene los datos estructurados para enviar a OnBase y los archivos adjuntos.
   * @param dataModel Objeto Data que contiene los datos del formulario del usuario.
   * @param interactionDto Objeto InteractionDto que representa la interacción que se va a guardar.
   */
  public auth(captcha: CaptchaDto, dataDto: DataRequestDto, dataModel: Data, interactionDto: InteractionDto) {
    this.facade.auth(captcha, (response) => {
      if (response) {
        // Obtener la aceptación de términos y condiciones y tratamiento de datos
        const termsAndConditionsObservable = this.facade.acceptanceTemsAndConditions(
          dataModel?.typeDocument || '',
          dataModel?.numberDocument || '',
        );
        const aceptacionObservable = this.facade.aceptacionTratamientoDatos(
          dataModel?.typeDocument || '',
          dataModel?.numberDocument || '',
        );

        // Combinar las respuestas de aceptación y términos y condiciones
        forkJoin([aceptacionObservable, termsAndConditionsObservable]).subscribe(
          ([aceptacionResponse, termsAndConditionsResponse]) => {
            // Desencriptar y deserializar las respuestas de aceptación y términos y condiciones
            const aceptacionDecrypt = this.encryptService.toDecrypt(aceptacionResponse.data || '');

            const aceptacionDeserealized = JSON.parse(aceptacionDecrypt) as AceptacionesResultDto;

            const termsAndConditionsDecrypt = this.encryptService.toDecrypt(termsAndConditionsResponse.data || '');

            const termsAndConditionsDeserealized = JSON.parse(termsAndConditionsDecrypt) as AceptacionesResultDto;

            // Verificar que las respuestas de aceptación y términos y condiciones sean exitosas
            if (
              aceptacionDeserealized.status?.statusCode === 200 &&
              termsAndConditionsDeserealized.status?.statusCode === 200
            ) {
              // Se guarda la interacción
              this.facade.saveInteraction(interactionDto, (responseInteraction, idInteraction, responseDataInteraction) => {
                // Valida si ya existen registros con data similar en los últimos 5 minutos
                if (responseDataInteraction.message != null) {
                  this.facade.spinner.hide();
                  this.modalLogic.getDuplicatedInteractionModal();
                  return;
                }

                if (responseInteraction) {
                  // Actualizar los datos con el ID de interacción
                  dataDto.documents?.forEach((putIdInteraction) => {
                    putIdInteraction.jsonData?.Keywords?.forEach((putKey) => {
                      if (putKey.Name === '' && putKey.Value === 0) {
                        putKey.Name = 'ID Interacción';
                      }
                      putKey.Value = idInteraction;
                    });
                  });
                  // Agregar el ID de interacción a la lista de KeywordsDto si existe
                  const interactionform = dataDto?.onBaseDto?.eFormList;
                  if (interactionform && interactionform.length > 0) {
                    const keywordsList = interactionform[0]?.keyWords;
                    if (keywordsList && keywordsList.length > 0) {
                      // Agregar el nuevo campo a la lista de KeywordsDto
                      keywordsList.push({ name: 'ID Interacción', value: `${idInteraction}` });
                    }
                  }
                  // Enviar correo electrónico utilizando los datos preparados
                  this.facade.onBase(dataDto, (responseEmail) => {
                    if (responseEmail) {
                      // Mostrar el modal de solicitud después de completar el envío del correo
                      this.modalLogic.getRequestModal();
                    }
                  });
                }
              });
            } else {
              // Ocultar el spinner si la autenticación o aceptación de términos y condiciones falla
              this.facade.spinner.hide();
            }
          },
        );
      }
    });
  }

  /**
   * Método encargado de mostrar los modal según a quién hace la solitud
   */
  public modalTypeCustomer() {
    const form = this.frmGroup.value;

    switch (form.typeCustomer.id) {
      case 7:
        this.modalLogic.getEpsModal();
        break;
      case 8:
        this.modalLogic.getArlModal();
        break;
      case 9:
        this.modalLogic.getJudicialEntityModal();
        break;
      case 10:
        this.modalLogic.getQualifyModal();
        break;
      default:
    }
  }

  /**
   * Método encargado de contar los caracteres de la descripción
   */
  public count() {
    const form = this.frmGroup.value;
    this.valor = form.detailSubject.length;
  }

  /**
   * Valida el estado del botón de subida de archivos.
   *
   * @returns `true` si el botón debe estar deshabilitado, `false` en caso contrario.
   */
  public validateButton(): boolean {
    let response = false;
    this.infoDisableButtonFileUp$.subscribe((x) => {
      response = x;
    });

    return response;
  }

  /**
   * Metodo encargado de obtener la fecha en un formato especifico
   */
  public getDate(): string {
    const now: Date = new Date();

    // Obtener los componentes de la fecha y hora actual
    const year: number = now.getFullYear();
    // Los meses son indexados desde 0
    const month: number = now.getMonth() + 1;
    const day: number = now.getDate();
    const hours: number = now.getHours();
    const minutes: number = now.getMinutes();
    const seconds: number = now.getSeconds();

    // Formatear la fecha y hora en el formato especificado
    const formattedDateTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours
      .toString()
      .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    return formattedDateTime;
  }

  /**
   * Metodo encargado de detectar emogis
   * @returns si el campo contiene emogis o no
   */
  public noEmojiValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null; // Si el campo está vacío, no valida emojis
      }
      const regex = emojiRegex();
      const hasEmoji = regex.test(control.value)
      return hasEmoji ? { noEmoji: true } : null;
    };
  }

  /**
   * Metodo encargado de comparar el correo de confirmación
   * con el correo electrónico principal
   * @returns Función validadora
   */
  public emailConfirmationValidator(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {

      // Obtiene el valor del campo email
      const email = group.get('email')?.value;

      // Obtiene el valor del campo confirmación de email
      const emailConfirmation = group.get('emailConfirmation')?.value;

      // Verifica que ningún campo esté vacío
      if (!email || !emailConfirmation) {
        return null;
      }

      // Define la condición
      const emailsMatch = email === emailConfirmation;

      // Retorna el objeto validador
      return emailsMatch ? null : { noMatchEmails: true };
    }
  }

  /**
   * Metodo encargado de prevenir los eventos del
   * portapapeles en el formulario
   */
  public preventDefault(event: ClipboardEvent): void {
    event.preventDefault();
  }
}
