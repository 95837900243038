import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FileData } from '../../Models/file-data.model';
import { FileUploadFacade } from './file-upload.facade';
import { PDFDocument } from 'pdf-lib';
import { ModalLogic } from '../../../shared/logic/modal.logic';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

/**
 * Componente para carga de archivos
 */
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnDestroy {
  /**
   * ViewChild para poder hacer el llamado de fileInput2
   */
  @ViewChild('fileInput2') public fileInput2!: ElementRef;

  /**
   * Facade
   */
  public facade: FileUploadFacade;
  /**
   * BehaviorSubject para mostrar archivo subido
   */
  private readonly BehaviorSubjectMessageSelectFile = new BehaviorSubject<string>('Seleccione su archivo');
  /**
   * Información de los archivos seleccionados
   */
  public dataSelected$ = new Observable<FileData[]>();
  /**
   * Observable para destruir un flujo
   */
  public destroy$ = new Subject<boolean>();
  /**
   * Ancho de la pantalla
   */
  public screenWidth!: number;
  /**
   * validar boton
   */
  public disableButtonFileUp = false;
  /**
   * Lista de Archivos seleccionados
   */
  public selectedFiles?: FileList;
  /**
   * Arreglo con la información de los archivos.
   */
  private files: FileData[] = [];
  /**
   * Contiene los controles del formulario
   */
  public formGroup: FormGroup;
  /**
   * Creador de Formulario para ser expuesto
   */
  public formBuilder: FormBuilder;
  /**
   * Creador de Formulario para ser expuesto
   */
  public modalLogic: ModalLogic = new ModalLogic();
  /**
   * Bandera para validar estado de archivos
   */
  public validateFiles = false;
  /**
   * Bandera para validar tamaño total de archivos
   */
  public validateSizeFiles = false;
  /**
   * Bandera para validar si el pop up ya se ha mostrado
   */
  public hasShownPopUp: boolean = false;
  /**
   * Mensaje de alerta de archivos
   */
  public messageValidateFiles = '';
  /**
   * Nombre de Campo para Archivos
   */
  public readonly filesFieldname = 'files';
  /**
   * Value spinner
   */
  public valueSpinner = 0;

  @HostListener('window:resize', ['$event'])
  public getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  /**
   * Constructor del componente FileUploadComponent.
   * @param formBuilder Provee azúcar sintáctico que acorta la creación de instancias de `FormControl`.
   * @param facade Facade para la lógica de negocio de carga de archivos.
   */
  public constructor(formBuilder: FormBuilder, facade: FileUploadFacade) {
    this.formBuilder = formBuilder;
    this.facade = facade;
    this.dataSelected$ = this.facade.dataSelected$;
    this.formGroup = this.formBuilder.group({});
    this.getScreenSize();
    this.facade.setDisableButtonFileUp(false);
  }

  /**
   * Método que se invoca al destruir el componente.
   * Se encarga de liberar recursos y finalizar observables.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.facade.ngOnDestroy();
  }

  /**
   * Método que se invoca al inicializar el componente.
   * Se suscribe al flujo de datos de archivos seleccionados.
   */
  public ngOnInit(): void {
    this.dataSelected$.pipe(takeUntil(this.destroy$)).subscribe();
  }

  /**
   * Método encargado de procesar la carga de archivos.
   * @param event Evento de carga de archivos desde el input file.
   */
  public async upload(event: any) {
    // Reiniciar banderas y valores antes de procesar la carga
    this.validateFiles = false;
    this.validateSizeFiles = false;
    this.facade.setDisableButtonFileUp(false);
    this.selectedFiles = event.target.files;
    const name = '';

    // Bandera para verificar si todos los archivos están protegidos por contraseña
    let someFileProtected = false;
    let someFileCorrupted = false;
    // Obtener control del formulario para los archivos
    const filesControl = this.formGroup.get(this.filesFieldname);

    // Función para quitar caracteres no permitidos
    const sanitizeFileName = (name: string): string => {
      return name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[ñÑ´¨¡¿¬°+#&]/g, (char) => {
          switch (char) {
            case 'ñ': return 'n';
            case 'Ñ': return 'N';
            default: return '';
          }
        });
    };

    if (this.selectedFiles && this.selectedFiles[0]) {
      let globalSize = this.files.reduce((totalSize, file) => {
        // Verificar si file.size está definido antes de sumarlo
        if (file.size !== undefined) {
          return totalSize + file.size;
        }

        return totalSize;
      }, 0);

      // Actualizar valor del spinner
      this.valueSpinner = 20;
      // Simular un retardo para mostrar progreso
      await this.delay(300);

      const fileListArray = Array.from(this.selectedFiles);
      // Iterar sobre los archivos seleccionados para procesar cada uno
      for (let selectedFile of fileListArray) {
        selectedFile = new File([selectedFile], sanitizeFileName(selectedFile.name), { type: selectedFile.type });
        const fileExtension = selectedFile.name.slice((Math.max(0, selectedFile.name.lastIndexOf(".")) || Infinity) + 1).toLocaleLowerCase();
        // Verificar tipo de archivo permitido (pdf, jpg, jpeg, png)
        switch (fileExtension) {
          case 'pdf':
          case 'jpg':
          case 'jpeg':
          case 'png':
            // Procesar archivo PDF para verificar si está protegido por contraseña
            if (fileExtension === 'pdf') {
              const reader = new FileReader();
              reader.onload = async (e: any) => {
                const result = e.target.result;
                try {
                  const pdfDoc = await PDFDocument.load(result, { ignoreEncryption: true, });
                  if (pdfDoc.isEncrypted) {
                    someFileProtected = true;
                  } else {
                    const pdf = await pdfjsLib.getDocument({ data: result }).promise;
                    console.log(pdf)
                  }
                } catch (error) {
                  // Archivo PDF puede estar malformado o corrupto
                  someFileCorrupted = true;
                }
              };
              reader.readAsArrayBuffer(selectedFile);
            } else {
              someFileCorrupted = await this.isImageCorrupted(selectedFile);
            }

            // Verificar si el archivo ya ha sido cargado previamente
            const fileAlreadyUploaded = this.files.some(
              (file) => file.name === selectedFile.name || file.lastModified === selectedFile.lastModified,
            );

            // Actualizar valor del spinner
            this.valueSpinner = 65;
            // Simular un retardo para mostrar progreso
            await this.delay(300);

            // Verificar tamaño del archivo antes de proceder con la carga
            if (selectedFile.size <= 9000000) {
              // Actualizar valor del spinner
              this.valueSpinner = 100;
              // Simular un retardo para mostrar progreso
              await this.delay(300);

              globalSize += selectedFile.size;

              // Crear objeto FileData para el archivo actual
              const fileData: FileData = {
                lastModified: selectedFile.lastModified,
                name: selectedFile.name,
                size: selectedFile.size,
                type: selectedFile.type,
              };

              // Verificar si el archivo no ha sido cargado y no excede el tamaño permitido y no está protegido por contraseña
              if (!fileAlreadyUploaded && globalSize <= 9000000 && !someFileProtected && !someFileCorrupted) {
                // Añadir archivo a la lista de archivos cargados
                this.facade.setFileFileUploaded(fileData);

                // Leer contenido del archivo como base64 y agregarlo a fileData
                const reader = new FileReader();
                reader.onload = (e: any) => {
                  fileData.content = e.target.result;
                  this.files.push(fileData);
                  // Actualizar valor del control del formulario con la lista de archivos
                  filesControl?.setValue(this.files);
                  // Limpiar errores del control del formulario
                  filesControl?.setErrors(null);
                };
                reader.readAsDataURL(selectedFile);
              } else if (fileAlreadyUploaded) {
                // Mostrar mensaje de error si el archivo ya ha sido cargado
                this.validateFiles = true;
                this.facade.setDisableButtonFileUp(false);
                this.messageValidateFiles = 'Hola, detectamos que estas cargando un archivo duplicado.';
              } else if (someFileProtected || someFileCorrupted) {
                // Mostrar mensaje de error si el archivo PDF está protegido por contraseña
                this.validateFiles = true;
                this.facade.setDisableButtonFileUp(false);
                this.messageValidateFiles = 'Hola, detectamos que estás cargando un archivo con contraseña o un archivo inaccesible (dañado).';
                this.modalLogic.getCorruptedOrProtectedFilesModal();
              }
            } else if (selectedFile.size > 9000000) {
              // Mostrar mensaje de error si el archivo excede el tamaño permitido
              this.validateFiles = true;
              this.facade.setDisableButtonFileUp(false);
              this.messageValidateFiles = 'Hola, Los archivos de tu cargue superan el peso permitido.';
              this.modalLogic.getHeavyImageModal();
            }
            break;
          default:
            // Mostrar mensaje de error si el tipo de archivo no está permitido
            this.validateFiles = true;
            this.facade.setDisableButtonFileUp(false);
            this.messageValidateFiles = 'Hola, detectamos tipo de archivo no permitido en tu cargue.';
            this.modalLogic.getFormatNotAllowedModal();
            this.valueSpinner = 100;
        }
      }
      // Verificar si el tamaño total de archivos excede el límite permitido
      if (globalSize > 9000000) {
        this.validateFiles = true;
        this.facade.setDisableButtonFileUp(false);
        this.messageValidateFiles = 'Hola, Los archivos de tu cargue superan el peso permitido.';
        this.modalLogic.getHeavyImageModal();
      }

      // Actualizar el mensaje del BehaviorSubjectMessageSelectFile
      this.BehaviorSubjectMessageSelectFile.next(name);
    }
  }

  /**
   * Metodo para validar imagenes dañadas
   * @param file imagen cargada
   * @returns si la imagen se proceso bien o con error
   */
  public isImageCorrupted(file: File): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(false);
      img.onerror = () => resolve(true);
      img.src = URL.createObjectURL(file);
    });
  }
  /**
   * Delay
   * @param ms milisegundos
   */
  public async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Metodo para eliminar archivos
   * @param name nombre del archivo a eliminar
   */
  public deleteItem(name: string): void {
    let flag = true;
    this.dataSelected$
      .pipe(
        map((response) => response.filter((res) => res.name !== name)),
        takeUntil(this.destroy$),
      )
      .subscribe((x) => {
        if (flag) {
          flag = false;
          this.facade.setFileFileUpload(x);
          this.files = this.files.filter((file) => file.name !== name);
          this.fileInput2.nativeElement.value = '';
        }
      });
  }

  /**
   * Metodo que muestra el popup al seleccionar archivos
   */
  public showInputFilePopUp() : void {
    // Valida si el pop up ya se ha mostrado
    if (!this.hasShownPopUp) {

      // Define que el pop up ya fué mostrado
      this.hasShownPopUp = true;
      // Muestra el pop up e invoca el selector de archivos
      this.modalLogic.getFileUploadInformationModal(() => this.fileInput2.nativeElement.click());
    } else {
      // Si el pop up ya se mostró solo se abrirá el selector de archivos
      this.fileInput2.nativeElement.click();
    }
  }

  /**
   * onSubmit
   */
  public onSubmit() { }
}
